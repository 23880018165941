import React, { useEffect, useMemo, useRef, useState } from "react"
import dayjs from "dayjs"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { useQuery } from "hooks/query"
import { Button, Spinner } from "reactstrap"
import { useAlert } from "react-alert"
import { getEncodedQuery } from "utils/query"
import { makeApiCall, makeApiDownloadCall } from "api/generic-api"
import lookups from "configuration/lookups"
import { useRequisitionsConfigurationQuery } from "api/getters"
import GeneralFormRequisitionsConfigurationNew from "components/requisitions-configuration/general-form-requisitions-configuration-new"
import requisitionsConfigFullDefinition from "configuration/requisitions-configuration-full-definition"

export function RequisitionConfigurationTable({ type = "cancer", pageSize: pageSizeProp, customSelect = {}, tableKey, downloadFileName = "", ...rest }) {
  const alert = useAlert()
  const query = useQuery()

  const navigate = useNavigate()
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const [publishId, setPublishId] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [testConfiguration, setTestConfiguration] = useState([])
  const [loading, setLoading] = useState(false)

  const structure = requisitionsConfigFullDefinition
  const formStructure = structure.form

  const {
    data: requisitionConfigListData,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = useRequisitionsConfigurationQuery({
    variables: {
      sort,
      page,
      selector: {
        ...selector,
        ...customSelect,
      },
      pageSize: pageSizeProp || pageSize,
    },
  })

  const hasRole = (role) => {
    const roles = rest.applicationState.authentication.user?.roles
    return roles && roles.length && roles.includes(role)
  }

  const isAdmin = rest.applicationState.authentication.user.permission === "administrator"

  useEffect(() => {
    getTestConfiguration()
  }, [])

  const getTestConfiguration = async () => {
    setLoading(true)
    const result = await makeApiCall("get", `/api/test-configuration`)
    setTestConfiguration(result.body)
    setLoading(false)
  }

  const updateRequisitionConfig = async (formState) => {
    const result = await makeApiCall("post", `/api/requisitions-configuration`, formState)
    if (result.ok) {
      alert.show("Successfully updated", {
        type: "success",
      })
      refetch()
    } else {
      const details = `Details: code ${result.statusCode}; ${result.error}`
      alert.show(details, {
        type: "error",
      })
    }

    return result
  }

  useEffect(() => {
    return () => {
      remove()
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Test Group",
        accessor: "configName",
        hasSort: true,
      },
      {
        Header: "Created At",
        accessor: "createdTimestamp",
        hasSort: true,
      },
      {
        Header: "Updated At",
        accessor: "updatedTimestamp",
        hasSort: true,
      },
      {
        Header: "Published At",
        accessor: "publishedTimestamp",
        hasSort: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        skipCsv: true,
      },
    ],
    [],
  )

  const activeRequisitionConfig = requisitionConfigListData?.find((item) => item?.id === query.get("id"))

  const handlePublish = async (_record) => {
    try {
      setPublishId(_record.id)
      setPublishing(true)
      const result = await makeApiCall("put", `/api/requisitions-configuration/${_record.id}`, _record)
      if (result.ok) {
        alert.show("Successfully published", {
          type: "success",
        })
        refetch()
      } else {
        const details = `Details: code ${result.statusCode}; ${result.error}`
        alert.show(details, {
          type: "error",
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setPublishing(false)
    }
  }

  const data = useMemo(
    () =>
      (requisitionConfigListData &&
        requisitionConfigListData?.map((item) => ({
          ...item,
          publishedTimestamp: item?.publishedTimestamp ? dayjs(item?.publishedTimestamp).format("MM/DD/YYYY") : "",
          updatedTimestamp: item?.updatedTimestamp ? dayjs(item?.updatedTimestamp).format("MM/DD/YYYY") : "",
          createdTimestamp: item?.createdTimestamp ? dayjs(item?.createdTimestamp).format("MM/DD/YYYY") : "",
          actions: (isAdmin || hasRole("laboratoryDirector")) && (
            <div className="d-flex gap-2 text-nowrap">
              <Link
                to={`/requisitions-configuration?${getEncodedQuery({ id: item.id, testId: "" }, "", query.toString())}`}
                className="btn btn-outline-primary"
              >
                View
              </Link>
              <Button outline disabled={publishId === item?.id && publishing} onClick={() => handlePublish(item)}>
                {publishId === item?.id && publishing ? <Spinner size="sm" /> : "Publish"}
              </Button>
            </div>
          ),
        }))) ||
      [],
    [requisitionConfigListData, publishId, publishing],
  )

  const id = query?.get("id")
  const isNew = id === "new"

  if (Boolean(id)) {
    return (
      <div>
        {isLoading ? (
          <Spinner type="grow" />
        ) : (
          <GeneralFormRequisitionsConfigurationNew
            formDefinition={formStructure}
            formState={isNew ? null : activeRequisitionConfig}
            lookups={lookups}
            update={updateRequisitionConfig}
            submit={updateRequisitionConfig}
            formSource={[
              {
                name: "testType",
                data: testConfiguration,
              },
            ]}
            close={() => navigate(-1)}
            {...rest}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      <TableContainer
        onAdd={() => navigate(`/requisitions-configuration?${getEncodedQuery({ id: "new" }, "", query.toString())}`)}
        downloadFileName={downloadFileName}
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || loading}
        filters={[]}
        selector={selector}
        defaultSort="createdTimestamp"
        defaultSortDir="asc"
        {...rest}
      />
    </div>
  )
}
