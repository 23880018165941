import React, { useMemo, useState, useEffect } from "react"
import axios from "axios"
import { useExtrasConfigurationQuery } from "api/getters"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"
import { Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import ReactSelect from "react-select"
import { makeApiCall } from "api/generic-api"

dayjs.extend(utc)

export function ExtrasConfigurationTable({ pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()
  const {
    data: extrasData,
    isLoading,
    isFetching,
    refetch,
  } = useExtrasConfigurationQuery({
    variables: {
      sort,
      page,
      selector,
      pageSize: pageSizeProp || pageSize,
    },
  })

  const [alert, setAlert] = useState({ state: null, message: "" })
  const [modalOpen, setModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)

  useEffect(() => {
    let timer
    if (alert.state) {
      timer = setTimeout(() => {
        setAlert({ state: null, message: "" })
      }, 2000)
    }
    return () => clearTimeout(timer)
  }, [alert])

  const columns = useMemo(
    () => [
      {
        Header: "key",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "extraName",
      },
      {
        Header: "value",
        accessor: "extraValue",
      },

      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/extra/${itemToDelete?.id}`)
      setAlert({ state: "success", message: "Extras Configuration has been successfully deleted." })
      refetch()
      setModalOpen(false)
      setItemToDelete(null)
    } catch (error) {
      setAlert({
        state: "error",
        message: "There was a problem submitting your data. Please try again or contact us..",
      })
    }
  }

  const handleDeleteClick = (item) => {
    setItemToDelete(item)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setItemToDelete(null)
  }

  const data = useMemo(
    () =>
      extrasData?.map((item) => ({
        id: item?.id,
        extraName: item?.extraName,
        extraValue: item?.extraValue,
        action: (
          <div className="d-flex gap-2">
            <Link to={`/extras-configuration/${item.id}`} className="btn btn-outline-primary">
              View
            </Link>
            <Button outline color="danger" onClick={() => handleDeleteClick(item)}>
              Delete
            </Button>
          </div>
        ),
      })) || [],
    [extrasData],
  )

  const filters = useMemo(
    () => [
      {
        field: "_id",
        label: "Search Key",
        placeholder: "Type in Key",
      },
    ],
    [],
  )

  return (
    <div>
      {alert.state === "success" && (
        <Alert style={{ marginTop: "10px", textAlign: "center" }} color="success">
          {alert.message}
        </Alert>
      )}
      {alert.state === "error" && (
        <Alert style={{ marginTop: "10px", textAlign: "center" }} color="danger">
          {alert.message}
        </Alert>
      )}
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        filters={filters}
        isLoading={isLoading || isFetching}
        selector={selector}
        showAdd="/extras-configuration-form"
        sort={sort}
        downloadFileName="Extras"
        {...rest}
      />
      <Modal isOpen={modalOpen} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to remove {itemToDelete?.id}?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            OK
          </Button>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
