import React, { useState, useEffect } from "react"
import { Button, Label, Row, Col, Dropdown, DropdownToggle, Spinner, DropdownMenu, DropdownItem, Alert } from "reactstrap"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { makeApiCall } from "api/generic-api"
import { usePriceFormSubmit } from "api/getters"

const schema = Yup.object().shape({
  productId: Yup.string().required("Product ID is required"),
  payerType: Yup.string().required("Payer Type is required"),
  // feeCode: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Fee Code must be alphanumeric"),
  price: Yup.number().typeError("Price must be a number").required("Price is required").moreThan(0, "Please enter a valid price"),
})

const PriceForm = ({ item }) => {
  const [testConfiguration, setTestConfiguration] = useState([])
  const [payerTypeConfiguration, setPayerTypeConfiguration] = useState([])
  const [lookups, setLookups] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [payerDropdownOpen, setPayerDropdownOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [selectedLookup, setSelectedLookup] = useState(null)
  const [selectedPayerType, setSelectedPayerType] = useState(null)

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      productId: item?.productId || "",
      payerType: item?.payerType || "",
      feeCode: item?.feeCode || "",
      price: item?.price || "",
    },
  })

  const {
    mutate,
    isLoading: mutating,
    isError,
    error,
  } = usePriceFormSubmit({
    onSuccess: () => {
      setSuccessMessage("Price has been successfully added.")
      setErrorMessage("")
      setIsSubmitted(true)
    },
    onError: (error) => {
      const apiErrorMessage = error?.response?.data?.error
      console.error("API Error:", apiErrorMessage)
      setErrorMessage(apiErrorMessage || "An unexpected error occurred.")
    },
  })

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)
  const togglePayerDropdown = () => setPayerDropdownOpen((prevState) => !prevState)

  const handleLookupChange = (lookup) => {
    if (lookup) {
      setSelectedLookup(lookup)
      setValue("productId", lookup._id)
    } else {
      setSelectedLookup(null)
      setValue("productId", "")
    }
    clearErrors("productId")
  }

  const handlePayerTypeChange = (payerType) => {
    if (payerType) {
      setSelectedPayerType(payerType)
      setValue("payerType", payerType)
    } else {
      setSelectedPayerType(null)
      setValue("payerType", "")
    }
    clearErrors("payerType")
  }

  const fetchConfigurations = async () => {
    setIsLoading(true)
    try {
      const [ProductResult, PayerTypeResult] = await Promise.all([makeApiCall("get", "/api/products"), makeApiCall("get", "/api/prices")])
      setTestConfiguration(ProductResult.body)
      setPayerTypeConfiguration(PayerTypeResult.body)

      setLookups(
        ProductResult.body.map((product) => ({
          _id: product.id,
          type: "product",
          key: product.id,
          value: product.productName,
        })),
      )
    } catch (error) {
      const errorMessage = error?.response?.data?.error || "Failed to fetch configurations"
      setErrorMessage(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchConfigurations()
  }, [])

  const payerArray = Array.from(
    new Set(payerTypeConfiguration.flatMap((item) => item.payerType || []).map((payer) => payer.charAt(0).toUpperCase() + payer.slice(1))),
  )

  const onSubmit = (data) => {
    const formValues = {
      productId: data.productId,
      payerType: data.payerType,
      feeCode: data.feeCode,
      price: data.price,
    }
    mutate(formValues)
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner size="sm" />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {errorMessage && (
          <Alert color="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert color="success" className="mt-3">
            {successMessage}
          </Alert>
        )}
        <Col className="mb-3" lg="6">
          <Label className="form-label">Product ID *</Label>
          <Controller
            name="productId"
            control={control}
            render={({ field }) => (
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className={`form-control text-start px-3 ${errors.productId ? "is-invalid" : ""}`}
                  disabled={isSubmitted}
                  style={{
                    backgroundColor: "transparent",
                    border: errors.productId ? "1px solid #dc3545" : "1px solid #ccc",
                    color: "#000",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  caret={!errors.productId}
                >
                  {selectedLookup ? selectedLookup.value : "Select Product"}
                </DropdownToggle>
                <DropdownMenu
                  className="mt-2"
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  <DropdownItem key="clear" onClick={() => handleLookupChange(null)} style={{ color: "#999" }}>
                    Clear Selection
                  </DropdownItem>
                  {lookups.map((lookup) => (
                    <DropdownItem key={lookup._id} onClick={() => handleLookupChange(lookup)} active={selectedLookup && selectedLookup._id === lookup._id}>
                      {lookup.value}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          />
          {errors.productId && (
            <p className="text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.productId.message}
            </p>
          )}
        </Col>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Payer Type *</Label>
          <Controller
            name="payerType"
            control={control}
            render={({ field }) => (
              <Dropdown isOpen={payerDropdownOpen} toggle={togglePayerDropdown}>
                <DropdownToggle
                  className={`form-control text-start px-3 ${errors.payerType ? "is-invalid" : ""}`}
                  disabled={isSubmitted}
                  style={{
                    backgroundColor: "transparent",
                    border: errors.payerType ? "1px solid #dc3545" : "1px solid #ccc",
                    color: "#000",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  caret={!errors.payerType}
                >
                  {selectedPayerType || "Select Payer Type"}
                </DropdownToggle>
                <DropdownMenu className="mt-2">
                  <DropdownItem key="clear" onClick={() => handlePayerTypeChange(null)} style={{ color: "#999" }}>
                    Clear Selection
                  </DropdownItem>
                  {payerArray.map((payerType, index) => (
                    <DropdownItem key={index} onClick={() => handlePayerTypeChange(payerType)} active={selectedPayerType === payerType}>
                      {payerType}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          />
          {errors.payerType && (
            <p className="text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.payerType.message}
            </p>
          )}
        </Col>

        <Col className="mb-3" lg="6">
          <Label className="form-label">Fee Code</Label>
          <Controller
            name="feeCode"
            control={control}
            render={({ field }) => <input className={`form-control`} {...field} disabled={isSubmitted} placeholder="Enter Fee Code" />}
          />
          {/* {errors.feeCode && <div className="invalid-feedback">{errors.feeCode.message}</div>} */}
        </Col>

        <Col className="mb-3" lg="6">
          <Label className="form-label">Price *</Label>
          <Controller
            name="price"
            control={control}
            render={({ field }) => <input type="number" className={`form-control ${errors.price ? "is-invalid" : ""}`} {...field} placeholder="Enter Price" />}
          />
          {errors.price && <div className="invalid-feedback">{errors.price.message}</div>}
        </Col>

        <Col className="text-end">
          <Button color="primary" type="submit" disabled={isSubmitted}>
            {mutating ? <Spinner size="sm" /> : "Submit"}
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default PriceForm
