import React, { useMemo, useState } from "react";
import { useAnnouncementQuery } from "api/getters";
import dayjs from "dayjs";
import axios from "axios";
import { Link } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import { useTableQueryStore } from "stores/zustand/tableQueryStore";
import TableContainer from "./TableContainer";
import { Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

dayjs.extend(utc);

const MAX_LENGTH = 50;

export function NotificationsTable({ pageSize: pageSizeProp, ...rest }) {
    const { page, pageSize, selector, sort } = useTableQueryStore();
    const { data: announcementData, isLoading, isFetching, refetch, error } = useAnnouncementQuery({
        variables: {
            sort,
            page,
            selector,
            pageSize: pageSizeProp || pageSize,
        },
    });
    const [alert, setAlert] = useState({ state: null, message: "" });
    const [modalOpen, setModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const handleDelete = async () => {
        try {
            await axios.delete(`/api/notifications/${itemToDelete?.id}`);
            setAlert({ state: 'success', message: 'Notification has been successfully deleted.' });
            refetch();
            setModalOpen(false);
            setItemToDelete(null);
        } catch (error) {
            setAlert({ state: 'error', message: 'There was a problem deleting the notification. Please try again or contact support.' });
        }
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setItemToDelete(null);
    };

    const truncateText = (text) => {
        return text.length > MAX_LENGTH ? `${text.substring(0, MAX_LENGTH)}...` : text;
    };

    const columns = useMemo(
        () => [
            {
                Header: "Title",
                accessor: "title",
                Cell: ({ cell: { value } }) => truncateText(value),
            },
            {
                Header: "Message",
                accessor: "message",
                Cell: ({ cell: { value } }) => truncateText(value),
            },
            {
                Header: "Created at",
                accessor: "createdAt",
                hasSort: true,
            },
            {
                Header: "Updated at",
                accessor: "updatedAt",
                hasSort: true,
            },
            {
                Header: "Action",
                accessor: "action",
                skipCsv: true,
            },
        ],
        []
    );

    const data = useMemo(
        () =>
            (announcementData &&
                announcementData.map((item) => ({
                    id: item.id,
                    title: item.title,
                    message: item.message,
                    createdAt: item?.createdAt && dayjs(item?.createdAt).local().format("MM/DD/YYYY"),
                    updatedAt: item?.updatedAt && dayjs(item?.updatedAt).local().format("MM/DD/YYYY"),
                    action: (
                        <div className="d-flex gap-2">
                            <Link
                                to={`/notifications/${item.id}?title=${encodeURIComponent(item.title)}`}
                                className="btn btn-outline-primary"
                            >
                                View
                            </Link>
                            <Button outline color="danger" onClick={() => handleDeleteClick(item)}>
                                Delete
                            </Button>
                        </div>
                    ),
                }))) || [],
        [announcementData]
    );

    return (
        <div>
            {alert.state === 'success' && (
                <Alert style={{ marginTop: '10px', textAlign: 'center' }} color="success">
                    {alert.message}
                </Alert>
            )}
            {alert.state === 'error' && (
                <Alert style={{ marginTop: '10px', textAlign: 'center' }} color="danger">
                    {alert.message}
                </Alert>
            )}
            <TableContainer
                refetch={refetch}
                columns={columns}
                data={data}
                isLoading={isLoading || isFetching}
                selector={selector}
                sort={sort}
                defaultSort="createdAt"
                showAdd="/notifications-form"
                defaultSortDir="desc"
                downloadFileName="Announcements"
                {...rest}
            />
            <Modal isOpen={modalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Confirmation</ModalHeader>
                <ModalBody>
                    Are you sure you want to remove {itemToDelete?.title}?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>OK</Button>
                    <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
