import React, { useState, useEffect } from "react"
import { Button, Label, Row, Col, Dropdown, DropdownToggle, Spinner, DropdownMenu, DropdownItem, Alert } from "reactstrap"
import { FaTimes } from "react-icons/fa"
import { useProductFormSubmit } from "api/getters"
import * as Yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { makeApiCall } from "api/generic-api"

const schema = Yup.object().shape({
  productCode: Yup.string().required("Product Code is required"),
  productName: Yup.string().required("Product Name is required"),
  testType: Yup.array().required("Test Type is required"),
  status: Yup.string().required("Status is required"),
})

const ProductForm = () => {
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false)
  const [extrasConfigurationDropdownOpen, setExtrasConfigurationDropdownOpen] = useState(false)
  const [testTypeDropdownOpen, setTestTypeDropdownOpen] = useState(false)
  const [productGroupDropdownOpen, setProductGroupDropdownOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState("")
  const [selectedTestTypes, setSelectedTestTypes] = useState([])
  const [selectedExtrasConfiguration, setSelectedExtrasConfiguration] = useState([])
  const [selectedProductGroup, setSelectedProductGroup] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [testConfiguration, setTestConfiguration] = useState([])
  const [extrasConfiguration, setExtrasConfiguration] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [statusSelected, setStatusSelected] = useState(false)

  const fetchConfigurations = async () => {
    setIsLoading(true)
    try {
      const [testResult, extrasResult] = await Promise.all([makeApiCall("get", "/api/test-configuration"), makeApiCall("get", "/api/extras-products")])
      setTestConfiguration(testResult.body)
      setExtrasConfiguration(extrasResult.body)
    } catch (error) {
      if (error.response?.data?.error) {
        setErrorMessage(error.response.data.error)
      } else {
        setErrorMessage("Failed to fetch configurations")
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchConfigurations()
  }, [])

  const {
    mutate,
    isLoading: mutating,
    isError,
    error,
  } = useProductFormSubmit({
    onSuccess: () => {
      setSuccessMessage("Product has been successfully added.")
      setErrorMessage("")
      setIsSubmitted(true)
    },
    onError: (error) => {
      const apiErrorMessage = error?.response?.data?.error
      console.error("API Error:", apiErrorMessage)
      setErrorMessage(apiErrorMessage)
    },
  })

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const dropdownStates = {
    extrasConfiguration: extrasConfigurationDropdownOpen,
    status: statusDropdownOpen,
    testType: testTypeDropdownOpen,
    productGroup: productGroupDropdownOpen,
  }

  const toggleDropdown = (dropdownType) => {
    if (dropdownType in dropdownStates) {
      const stateSetter = {
        extrasConfiguration: setExtrasConfigurationDropdownOpen,
        status: setStatusDropdownOpen,
        testType: setTestTypeDropdownOpen,
        productGroup: setProductGroupDropdownOpen,
      }[dropdownType]
      stateSetter((prevState) => !prevState)
    }
  }

  const handleStatusChange = (status) => {
    setSelectedStatus(status)
    setValue("status", status)
    setStatusDropdownOpen(false)
    setSelectedStatus(status)
    setStatusSelected(true)
    toggleDropdown("status")
  }

  const handleExtrasConfigurationChange = (config) => {
    setSelectedExtrasConfiguration((prevSelectedExtras) =>
      prevSelectedExtras.includes(config) ? prevSelectedExtras.filter((extra) => extra !== config) : [...prevSelectedExtras, config],
    )
  }

  const handleTestTypeChange = (value) => {
    setSelectedTestTypes((prevSelectedTestTypes) =>
      prevSelectedTestTypes.includes(value) ? prevSelectedTestTypes.filter((type) => type !== value) : [...prevSelectedTestTypes, value],
    )
    setValue("testType", selectedTestTypes)
    clearErrors("testType")
  }

  const handleProductGroupChange = (group) => {
    setSelectedProductGroup(group)
  }

  const getSelectedTestTypesText = () => {
    return selectedTestTypes.length > 0 ? selectedTestTypes.join(", ") : "Select Test Type"
  }

  const clearSelectedTestTypes = () => {
    setSelectedTestTypes([])
  }

  const onSubmit = async (data) => {
    if (!selectedStatus) {
      setErrorMessage("Please select a Status.")
      return
    }

    const formValues = {
      id: data.productCode,
      productName: data.productName,
      testTypeData: selectedTestTypes.map((type) => ({
        label: type,
        value: type.charAt(0).toUpperCase(),
      })),
      extrasConfiguration:
        selectedExtrasConfiguration.length > 0
          ? selectedExtrasConfiguration.map((type) => ({
              label: type,
              value: type.charAt(0).toUpperCase(),
            }))
          : null,
      status: selectedStatus.toLowerCase(),
      productGroup: selectedProductGroup ?? null,
    }

    mutate(formValues)
  }

  const statusArray = Array.from(new Set(testConfiguration.flatMap((item) => (item.product?.status ? item.product.status.trim().toLowerCase() : "")))).map(
    (status) => status.charAt(0).toUpperCase() + status.slice(1),
  )

  const testArray = Array.from(new Set(testConfiguration.flatMap((item) => item.product?.testTypeData || []).map((type) => type.label)))
  const productGroupArray = Array.from(new Set(testConfiguration.flatMap((item) => item.product?.productGroup || [])))
  const extraConfigArray = Array.from(new Set(extrasConfiguration.flatMap((item) => item.extraValue || [])))

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Spinner size="sm" />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {successMessage && <Alert color="success">{successMessage}</Alert>}
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      <Row>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Product Code *</Label>
          <Controller
            name="productCode"
            control={control}
            render={({ field }) => (
              <input placeholder="Enter Product Code" {...field} className={`form-control ${errors.productCode ? "is-invalid" : ""}`} disabled={isSubmitted} />
            )}
          />
          {errors.productCode && <div className="invalid-feedback">{errors.productCode.message}</div>}
        </Col>

        <Col className="mb-3" lg="6">
          <Label className="form-label">Extras Configuration</Label>
          <Dropdown isOpen={extrasConfigurationDropdownOpen} toggle={() => toggleDropdown("extrasConfiguration")}>
            <DropdownToggle
              className="form-control text-start px-3"
              disabled={isSubmitted}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #ccc",
                color: "#000",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              {selectedExtrasConfiguration.length > 0 ? selectedExtrasConfiguration.join(", ") : "Select Extras Configuration"}
            </DropdownToggle>
            <DropdownMenu className="mt-2">
              {extraConfigArray.map((extrasConfiguration) => (
                <DropdownItem key={extrasConfiguration} toggle={false} onClick={(e) => e.stopPropagation()}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={extrasConfiguration}
                      value={extrasConfiguration}
                      checked={selectedExtrasConfiguration.includes(extrasConfiguration)}
                      onChange={() => handleExtrasConfigurationChange(extrasConfiguration)}
                    />
                    <label className="form-check-label cursor-pointer" htmlFor={extrasConfiguration}>
                      {extrasConfiguration}
                    </label>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Product Name *</Label>
          <Controller
            name="productName"
            control={control}
            render={({ field }) => (
              <input placeholder="Enter Product Name" {...field} className={`form-control ${errors.productName ? "is-invalid" : ""}`} disabled={isSubmitted} />
            )}
          />
          {errors.productName && <div className="invalid-feedback">{errors.productName.message}</div>}
        </Col>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Status *</Label>
          <Dropdown isOpen={statusDropdownOpen} toggle={() => toggleDropdown("status")}>
            <DropdownToggle
              className={`form-control text-start px-3 ${errors.status && !statusSelected ? "is-invalid" : ""}`}
              disabled={isSubmitted}
              style={{
                backgroundColor: "transparent",
                border: `1px solid ${errors.status && !statusSelected ? "#dc3545" : "#ccc"}`,
                color: "#000",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: errors.testType ? "0.5rem" : "1rem",
              }}
              caret={!errors.testType}
            >
              {selectedStatus || "Select Status"}
            </DropdownToggle>

            <DropdownMenu className="mt-2">
              {statusArray.map((status) => (
                <DropdownItem key={status} onClick={() => handleStatusChange(status)}>
                  {status}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {errors.status && !statusSelected && (
            <div className="mt-1 text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.status.message}
            </div>
          )}
        </Col>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Test Type *</Label>
          <Dropdown isOpen={testTypeDropdownOpen} toggle={() => toggleDropdown("testType")}>
            <DropdownToggle
              className={`form-control text-start px-3 ${errors.testType ? "is-invalid" : ""}`}
              style={{
                backgroundColor: "transparent",
                border: `1px solid ${errors.testType ? "#dc3545" : "#ccc"}`,
                color: "#000",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "0.25rem",
                padding: "0.5rem 1rem",
                paddingRight: errors.testType ? "0.5rem" : "1rem",
              }}
              caret={!errors.testType}
              disabled={isSubmitted}
            >
              <span style={{ flex: "1 1 auto", overflow: "auto", whiteSpace: "nowrap" }}>{getSelectedTestTypesText() || "Select Test Type"}</span>
              {selectedTestTypes.length > 0 && (
                <FaTimes
                  onClick={clearSelectedTestTypes}
                  style={{
                    cursor: "pointer",
                    fontSize: "1rem",
                    marginLeft: "1rem",
                    color: "#888",
                  }}
                />
              )}
            </DropdownToggle>
            <DropdownMenu className="mt-2">
              {testArray.map((testType) => (
                <DropdownItem key={testType} toggle={false} onClick={(e) => e.stopPropagation()}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={testType}
                      name="testType"
                      value={testType}
                      checked={selectedTestTypes.includes(testType)}
                      onChange={() => {
                        handleTestTypeChange(testType)
                        clearErrors("testType")
                      }}
                    />
                    <label className="form-check-label cursor-pointer" htmlFor={testType}>
                      {testType}
                    </label>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {errors.testType && (
            <div className="mt-1 text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.testType.message}
            </div>
          )}
        </Col>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Product Group</Label>
          <Dropdown isOpen={productGroupDropdownOpen} toggle={() => toggleDropdown("productGroup")}>
            <DropdownToggle
              className="form-control text-start px-3"
              disabled={isSubmitted}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #ccc",
                color: "#000",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              {selectedProductGroup || "Select Product Group"}
            </DropdownToggle>
            <DropdownMenu className="mt-2">
              <DropdownItem style={{ color: "#999" }} key="clear-selection" onClick={() => handleProductGroupChange(null)}>
                Clear Selection
              </DropdownItem>
              {productGroupArray.map((group) => (
                <DropdownItem key={group} onClick={() => handleProductGroupChange(group)}>
                  {group}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col className="text-end">
          <Button color="primary" type="submit" disabled={isSubmitted}>
            {mutating ? <Spinner size="sm" /> : "Submit"}
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default ProductForm
