import React, { useState, useEffect } from "react";
import { Spinner, Label, Row, Col, Button, Alert } from "reactstrap";
import dayjs from "dayjs";
import { useExtrasConfigurationInfo } from "api/getters";
import { useExtrasConfigurationInfoUpdate } from "api/mutators";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    value: Yup.string().required("Value is required"),
});

const ExtrasConfigurationDetails = ({ id }) => {
    const [editing, setEditing] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { handleSubmit, control, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const [recentData, setRecentData] = useState({
        extraValue: "",
        extraName: "",
    });

    const { data, isLoading, refetch } = useExtrasConfigurationInfo({
        variables: {
            page: 1,
            selector: {
                _id: id,
            },
        },
    });

    const ExtraData = data?.[0] || {};
    const ExtrasId = ExtraData?.id;

    const { mutate, isLoading: mutating } = useExtrasConfigurationInfoUpdate(ExtrasId, {
        onSuccess: () => {
            setSuccessMessage("Extras have been successfully updated.");
            setErrorMessage("");
            setIsSubmitted(true);
            setEditing(false);
            refetch(); // Refetch data after successful update
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            console.error("API Error:", apiErrorMessage);
            setErrorMessage(apiErrorMessage || "An error occurred");
        },
    });

    useEffect(() => {
        if (data) {
            const extra = data[0] || {};
            setRecentData({
                extraName: extra.extraName || "",
                extraValue: extra.extraValue || "",
            });
            setValue("name", extra.extraName || "");
            setValue("value", extra.extraValue || "");
        }
    }, [data, setValue]);

    const handleCancel = () => {
        reset({
            name: recentData.extraName,
            value: recentData.extraValue,
        });
        setEditing(false);
    };

    const onSubmit = async (formData) => {
        const payload = {
            id: ExtraData.id,
            extraName: formData.name,
            extraValue: formData.value,
        };

        mutate(payload);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isLoading ? (
                <Spinner type="grow" />
            ) : (
                <Row>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    {successMessage && <Alert color="success">{successMessage}</Alert>}

                    <Col md={12}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Key *</Label>

                            <input
                                style={{ width: "30%" }}
                                type="text"
                                className="form-control"
                                value={ExtraData.id || ""}
                                disabled
                                readOnly
                            />
                        </div>
                    </Col>

                    <Col md={12}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Name *</Label>
                            {editing ? (
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={ExtraData.extraName || ""}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                            style={{ width: "30%" }}
                                            disabled={isSubmitted || mutating}
                                        />
                                    )}
                                />
                            ) : (
                                <input
                                    style={{ width: "30%" }}
                                    type="text"
                                    className="form-control"
                                    value={ExtraData.extraName || ""}
                                    readOnly
                                    disabled
                                />
                            )}
                            {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                        </div>
                    </Col>

                    <Col md={12}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Value *</Label>
                            {editing ? (
                                <Controller
                                    name="value"
                                    control={control}
                                    defaultValue={ExtraData.extraValue || ""}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className={`form-control ${errors.value ? "is-invalid" : ""}`}
                                            style={{ width: "30%" }}
                                            disabled={isSubmitted || mutating}
                                        />
                                    )}
                                />
                            ) : (
                                <input
                                    style={{ width: "30%" }}
                                    type="text"
                                    className="form-control"
                                    value={ExtraData.extraValue || ""}
                                    readOnly
                                    disabled
                                />
                            )}
                            {errors.value && <div className="invalid-feedback">{errors.value.message}</div>}
                        </div>
                    </Col>

                    <div className="d-flex justify-content-end gap-3 mt-4">
                        {editing ? (
                            <>
                                <Button
                                    key="update"
                                    color="danger"
                                    type="submit"
                                    disabled={isSubmitted || mutating}
                                >
                                    Update
                                    {mutating && <Spinner size="sm" className="ms-2" />}
                                </Button>
                                <Button
                                    key="cancel"
                                    color="secondary"
                                    type="button"
                                    onClick={handleCancel}
                                    disabled={mutating}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <Button disabled={isSubmitted || mutating} color="primary" onClick={() => setEditing(true)}>
                                Edit
                            </Button>
                        )}
                    </div>
                </Row>
            )}
        </form>
    );
};

export default ExtrasConfigurationDetails;
