import React, { useState } from "react";
import {
    Button,
    Label,
    Row,
    Col,
    Spinner,
    Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useExtrasConfigurationFormSubmit } from "api/mutators";

const schema = Yup.object().shape({
    key: Yup.string().required("Key is required"),
    name: Yup.string().required("Name is required"),
    value: Yup.string().required("Value is required"),
});

const ExtrasConfigurationForm = ({ item }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            key: item?.key || "",
            name: item?.name || "",
            value: item?.value || "",
        },
    });
    const { mutate, isLoading: mutating } = useExtrasConfigurationFormSubmit({
        onSuccess: () => {
            setSuccessMessage("Extras have been successfully added");
            setErrorMessage("");
            setIsSubmitted(true);
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            setErrorMessage(apiErrorMessage || "An unexpected error occurred.");
        },
    });


    const onSubmit = (data) => {
        const formValues = {
            id: data.name,
            extraName: data.key,
            extraValue: data.value,
        };
        mutate(formValues);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                {errorMessage && (
                    <Alert color="danger" className="mt-3">
                        {errorMessage}
                    </Alert>
                )}
                {successMessage && (
                    <Alert color="success" className="mt-3">
                        {successMessage}
                    </Alert>
                )}

                <Col className="mb-3" lg="12">
                    <Label className="form-label">Key *</Label>
                    <Controller
                        name="key"
                        control={control}
                        render={({ field }) => (
                            <input
                                style={{ width: "30%" }}
                                className={`form-control ${errors.key ? "is-invalid" : ""}`}
                                {...field}
                                disabled={isSubmitted}
                                placeholder="Enter key"
                            />
                        )}
                    />
                    {errors.key && <div className="text-danger">{errors.key.message}</div>}
                </Col>

                <Col className="mb-3" lg="12">
                    <Label className="form-label">Name *</Label>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <input
                                style={{ width: "30%" }}
                                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                {...field}
                                disabled={isSubmitted}
                                placeholder="Enter name"
                            />
                        )}
                    />
                    {errors.name && <div className="text-danger">{errors.name.message}</div>}
                </Col>

                <Col className="mb-3" lg="12">
                    <Label className="form-label">Value *</Label>
                    <Controller
                        name="value"
                        control={control}
                        render={({ field }) => (
                            <input
                                style={{ width: "30%" }}
                                className={`form-control ${errors.value ? "is-invalid" : ""}`}
                                {...field}
                                disabled={isSubmitted}
                                placeholder="Enter value"
                            />
                        )}
                    />
                    {errors.value && <div className="text-danger">{errors.value.message}</div>}
                </Col>

                <Col className="text-end mt-3" lg="12">
                    <Button color="primary" type="submit" disabled={isSubmitted || mutating}>
                        {mutating ? <Spinner size="sm" /> : "Submit"}
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default ExtrasConfigurationForm;
