import React, { Fragment, useEffect, useState } from "react"
import { useTable, useGlobalFilter, useSortBy, useFilters, useExpanded, usePagination } from "react-table"
import { Table, Row, Col, Button, Input, Spinner } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./Filters"
import { useQuery } from "hooks/query"
import { getEncodedQuery } from "utils/query"
import { useNavigate } from "react-router-dom"
import GlobalSearchFilter from "./GlobalSearchFilter"
import { csvDownload } from "components/csv-downloader"
import qs from "qs"

const TableContainer = ({
  columns,
  data,
  isLoading = false,
  isGlobalFilter,
  customPageSize = 10,
  className,
  allowPageSize = false,
  allowPaginate = false,
  showColumnFilter = false,
  renderList,
  filters = [],
  selector,
  defaultSort,
  defaultSortDir,
  refetch = () => { },
  enableDelete = false,
  enableAdd = true,
  showDelete = false,
  handleDelete: handleDeleteProps = () => { },
  showAdd = false,
  handleAdd: handleAddProps = () => { },
  onAdd,
  downloadFileName = "",
  manualSortBy = true,
  allowRefresh = false,
  allowDownload = false,
  sort,
}) => {
  const navigate = useNavigate()
  const query = useQuery()
  const queryString = query.toString()
  const queryObject = qs.parse(queryString)
  const queryPageIndex = Number(query.get("page") || 1) - 1
  const [refreshing, setRefreshing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [Add, setAdd] = useState(false)
  const [pageIndex, setPageIndex] = useState(Number(query.get("page") || 1) - 1)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    setSortBy,
    state: { pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      manualSortBy,
      initialState: {
        pageSize: allowPageSize || allowPaginate ? Number(query.get("pageSize")) || customPageSize : customPageSize,
        sortBy: queryObject?.sort?.length
          ? Object.entries(queryObject?.sort?.[0]).map(([key, value]) => ({ id: key, desc: value === "desc" }))
          : defaultSort
            ? [{ id: defaultSort, desc: defaultSortDir === "desc" }]
            : [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  )

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
    setPageIndex(0)
  }

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    setPageIndex(page)
  }

  const handleRefresh = async () => {
    setRefreshing(true)
    await refetch()
    setRefreshing(false)
  }

  const handleDelete = async () => {
    setDeleting(true)
    await handleDeleteProps()
    setDeleting(false)
  }



  const handleAdd = async () => {
    setAdd(true)
    try {
      await handleAddProps()
      if (onAdd) {
        await onAdd()
      }
    } finally {
      setAdd(false)
      navigate(showAdd)
    }
  }

  useEffect(() => {
    if (allowPageSize || allowPaginate) {
      const params = {
        pageSize: Number(pageSize),
        page: Number(pageIndex) + 1,
        sort: sortBy?.length ? sortBy?.map((item) => ({ [item.id]: item.desc ? "desc" : "asc" })) : null,
      }

      navigate(
        {
          search: `?${getEncodedQuery(params, "", query.toString())}`,
        },
        { replace: true },
      )
    }
  }, [sortBy, pageSize, pageIndex, navigate, allowPageSize, allowPaginate])

  useEffect(() => {
    setPageIndex(queryPageIndex)
  }, [queryPageIndex])

  return (
    <Fragment>
      {allowPageSize && (
        <div className="d-flex justify-content-between mb-3 gap-2">
          <div>
            <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex gap-2">
            {filters.length > 0 && (
              <Button
                onClick={() => {
                  setSortBy(defaultSort ? [{ id: defaultSort, desc: defaultSortDir === "desc" }] : [])
                  setPageIndex(0)
                  setPageSize(customPageSize || 10)
                  navigate({ search: `` }, { replace: true })
                }}
              >
                Clear Filters
              </Button>
            )}
            <Button disabled={refreshing} onClick={handleRefresh}>
              {refreshing ? <Spinner size="sm" /> : <span className="fa fa-refresh"></span>}
            </Button>
            <Button onClick={() => csvDownload({ data, columns, title: downloadFileName })}>
              <span className="fa fa-download" />
            </Button>
            {Boolean(showDelete) && (
              <Button onClick={handleDelete} disabled={deleting || !Boolean(enableDelete)}>
                {deleting ? <Spinner size="sm" /> : <span className="fa fa-trash"></span>}
              </Button>
            )}
            {Boolean(showAdd) && (
              <Button onClick={onAdd || handleAdd} disabled={Add || !enableAdd}>
                {Add ? <Spinner size="sm" /> : <span className="fa fa-plus"></span>}
              </Button>
            )}
          </div>
        </div>
      )}
      {(allowRefresh || allowDownload) && (
        <div className="d-flex gap-2 justify-content-end mb-3">
          {allowRefresh && (
            <Button disabled={refreshing} onClick={handleRefresh}>
              {refreshing ? <Spinner size="sm" /> : <span className="fa fa-refresh"></span>}
            </Button>
          )}
          {allowDownload && (
            <Button onClick={() => csvDownload({ data, columns, title: downloadFileName })}>
              <span className="fa fa-download" />
            </Button>
          )}
        </div>
      )}
      {/* {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="success" className="btn-rounded  mb-2 me-2" onClick={handleOrderClicks}>
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleUserClick}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button type="button" color="success" className="btn-rounded mb-2 me-2" onClick={handleCustomerClick}>
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )} */}

      {isGlobalFilter && (
        <div className="d-flex justify-content-md-start flex-wrap mb-3 align-center gap-2 mb-3">
          <GlobalSearchFilter setPageIndex={setPageIndex} filters={filters} selector={selector} />
        </div>
      )}

      {allowPaginate && (
        <Row className="justify-content-md-center justify-content-center align-items-center mb-3">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button onClick={() => setPageIndex(0)} disabled={pageIndex === 0}>
                {"<<"}
              </Button>
              <Button onClick={() => setPageIndex((prev) => prev - 1)} disabled={pageIndex === 0}>
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {/* {pageIndex + 1} of {pageOptions.length} */}
              {/* {pageIndex + 1} */}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              className="m-auto font-weight-bold"
              type="number"
              min={1}
              style={{
                width: 70,
                appearance: "textfield" /*remove the up/down arrows (spinner) input*/,
                MozAppearance: "textfield" /*remove the up/down arrows (spinner) different browser input*/,
                WebkitAppearance: "none" /*remove the up/down arrows (spinner) different browser input*/,
              }}
              max={data?.length === 0 ? pageIndex + 1 : pageIndex + 2}
              value={pageIndex + 1}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1 m-auto">
              <Button onClick={() => setPageIndex((prev) => prev + 1)} disabled={data?.length === 0 || data?.length < pageSize}>
                {">"}
              </Button>
              {/* <button className="btn btn-outline-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {">>"}
              </button> */}
            </div>
          </Col>
        </Row>
      )}

      <div className="position-relative table-responsive react-table" style={{ minHeight: "160px" }}>
        {isLoading && (
          <div className="z-1 position-absolute h-100 w-100 d-flex align-items-center justify-content-center">
            <Spinner type="grow" />
          </div>
        )}
        {renderList ? (
          renderList()
        ) : (
          <Table hover {...getTableProps()} className={className}>
            <thead className="table-nowrap">
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.id}
                      style={{
                        textAlign: column.isNumeric ? "right" : "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: "0.8rem",
                        wordWrap: "break-word",
                      }}
                    >
                      <div
                        className="min-h-auto "
                        {...(column.hasSort ? column.getSortByToggleProps() : {})}
                        onClick={() => {
                          if (column.hasSort) {
                            column.toggleSortBy(!column.isSortedDesc, false)
                          }
                        }}
                      >
                        {column.render("Header")}
                        {column.hasSort && generateSortingIndicator(column)}
                      </div>
                      {showColumnFilter && <Filter column={column} />}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ opacity: isLoading ? "0.5" : "1" }}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => (
                        <td
                          key={cell.id}
                          {...cell.getCellProps()}
                          style={{
                            textAlign: cell?.column.isNumeric ? "right" : "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "0.8rem",
                            wordWrap: "break-word",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </Table>
        )}
        {!isLoading && data?.length === 0 && (
          <div className="position-absolute py-4 w-100 d-flex align-items-center justify-content-center">
            <span>No records available</span>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default TableContainer
