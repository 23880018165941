import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { usePriceList } from "api/getters";
import { useTableQueryStore } from "stores/zustand/tableQueryStore";
import TableContainer from "./TableContainer";
import { Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import ReactSelect from "react-select";
import { makeApiCall } from "api/generic-api";

dayjs.extend(utc);

export function PriceTable({ pageSize: pageSizeProp, ...rest }) {
    const { page, pageSize, selector, sort } = useTableQueryStore();
    const { data: priceData, isLoading, isFetching, refetch } = usePriceList({
        variables: {
            sort,
            page,
            selector,
            pageSize: pageSizeProp || pageSize,
        },
    });

    const [alertState, setAlertState] = useState(null);
    const [alertMessage, setAlertMessage] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [testTypes, setTestTypes] = useState([]);
    const [payerTypes, setPayerTypes] = useState([]);

    const fetchProductTypesAndPayers = async () => {
        try {
            const [productResponse, payerResponse] = await Promise.all([
                makeApiCall("get", "/api/products"),
                makeApiCall("get", "/api/prices")
            ]);
            const products = productResponse?.body || [];
            const extractedTestTypes = products.map(product => ({
                _id: product?.id,
                value: product?.id,
                label: product?.productName || 'Unknown Product',
            }));
            const prices = payerResponse?.body || [];
            const payerTypesSet = new Set(prices.map(price => price?.payerType?.toLowerCase()).filter(payerType => payerType));
            setTestTypes(extractedTestTypes);
            setPayerTypes(Array.from(payerTypesSet));
        } catch (error) {
            console.error("Error fetching products :", error);
            setAlertState('error');
            setAlertMessage('Failed to fetch product types and payer types.');
        }
    };

    useEffect(() => {
        fetchProductTypesAndPayers();
    }, []);


    useEffect(() => {
        let timer;
        if (alertState) {
            timer = setTimeout(() => {
                setAlertState(null);
                setAlertMessage("");
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [alertState]);

    const columns = useMemo(() => [
        {
            Header: "Product",
            accessor: "productId",
        },
        {
            Header: "Payer Type",
            accessor: "payerType",
        },
        {
            Header: "Fee Code",
            accessor: "feeCode",
        },
        {
            Header: "Price",
            accessor: "price",
        },
        {
            Header: "Action",
            accessor: "action",
            skipCsv: true,
        },
    ], []);

    const handleDelete = async () => {
        try {
            await axios.delete(`/api/price/${itemToDelete}`);
            setAlertState('success');
            setAlertMessage('Price has been successfully deleted');
            refetch();
            setModalOpen(false);
            setItemToDelete(null);
        } catch (error) {
            setAlertState('error');
            setAlertMessage('There was a problem deleting the price. Please try again or contact us.');
        }
    };

    const handleDeleteClick = (itemId) => {
        setItemToDelete(itemId);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setItemToDelete(null);
    };

    const data = useMemo(() =>
        (priceData && priceData.map((item) => ({
            productId: item?.productId,
            payerType: item?.payerType,
            feeCode: item?.feeCode,
            price: item?.price,
            action: (
                <div className="d-flex gap-2">
                    <Link to={`/prices-view/${item.id}`} className="btn btn-outline-primary">
                        View
                    </Link>
                    <Button outline color="danger" onClick={() => handleDeleteClick(item.id)}>
                        Delete
                    </Button>
                </div>
            ),
        }))) || [],
        [priceData]
    );

    const filters = useMemo(() => [
        {
            field: "productId",
            label: "Product",
            placeholder: "Select Product",
            renderCustom: (filter, value, onChange) => (
                <div className="input-group">
                    <ReactSelect
                        isClearable
                        classNamePrefix="select2-selection"
                        placeholder={filter.placeholder}
                        value={testTypes.find((item) => item.value === value) || null}
                        options={testTypes}
                        onChange={(selectedOption) => onChange(selectedOption?.value || "")}
                    />
                </div>
            ),
        },

        {
            field: "payerType",
            label: "Payer Type",
            placeholder: "Select Payer Type",
            renderCustom: (filter, value, onChange) => (
                <div className="input-group">
                    <ReactSelect
                        isClearable
                        classNamePrefix="select2-selection"
                        placeholder={filter.placeholder}
                        value={payerTypes.map(payerType => ({ value: payerType, label: payerType }))
                            .find(option => option.value === value) || null}
                        options={payerTypes.map(payerType => ({ value: payerType, label: payerType }))}
                        onChange={(selectedOption) => onChange(selectedOption?.value || "")}
                    />
                </div>
            ),
        },
        {
            field: "feeCode",
            label: "Search Fee Code",
            placeholder: "Type in Fee Code",
            removeSpaces: true,
        },
        {
            field: "price",
            label: "Search Price",
            placeholder: "Type in Price",
            removeSpaces: true,
        },
    ], [testTypes, payerTypes]);

    return (
        <div>
            {alertState === 'success' && (
                <Alert style={{ marginTop: '10px', textAlign: 'center' }} color="success">
                    {alertMessage}
                </Alert>
            )}
            {alertState === 'error' && (
                <Alert style={{ marginTop: '10px', textAlign: 'center' }} color="danger">
                    {alertMessage}
                </Alert>
            )}
            <TableContainer
                refetch={refetch}
                columns={columns}
                data={data}
                filters={filters}
                isLoading={isLoading || isFetching}
                selector={selector}
                showAdd="/prices-view-form"
                sort={sort}
                downloadFileName="Prices"
                {...rest}
            />
            <Modal isOpen={modalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Confirmation</ModalHeader>
                <ModalBody>
                    Are you sure you want to remove this entry?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>OK</Button>
                    <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
