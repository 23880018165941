import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useExtrasConfigurationQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["extrasConfiguration", variables], () => axiosClient.get(`/extras?${paramsToQuery(variables)}`).then((res) => res.data), { ...rest })
}

export function useExtrasConfigurationInfo({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["ExtrasConfigurationInfoQueries", variables], () => axiosClient.get(`/extras??${paramsToQuery(variables)}`).then((res) => res.data), {
    ...rest,
  })
}
