import React, { useEffect, useMemo, useRef, useState } from "react"
import dayjs from "dayjs"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { useQuery } from "hooks/query"
import { Button, Spinner } from "reactstrap"
import { useAlert } from "react-alert"
import { getEncodedQuery } from "utils/query"
import { makeApiCall, makeApiDownloadCall } from "api/generic-api"
import { useTemplatesQuery } from "api/getters/templates"
import templateFullDefinition from "configuration/template-full-definition"
import lookups from "configuration/lookups"
import GeneralFormTemplatesNew from "components/general-form-templates-new"

export function TemplatesUploadTable({ type = "cancer", pageSize: pageSizeProp, customSelect = {}, tableKey, downloadFileName = "", ...rest }) {
  const alert = useAlert()
  const query = useQuery()

  const navigate = useNavigate()
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const [downloadId, setDownloadId] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [testConfiguration, setTestConfiguration] = useState([])
  const [loading, setLoading] = useState(false)

  const structure = templateFullDefinition
  const formStructure = structure.form

  const {
    data: templateListData,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = useTemplatesQuery({
    // refetchInterval: 3000,
    variables: {
      sort,
      page,
      selector: {
        ...selector,
        ...customSelect,
      },
      pageSize: pageSizeProp || pageSize,
    },
  })

  const hasRole = (role) => {
    const roles = rest.applicationState.authentication.user?.roles
    return roles && roles.length && roles.includes(role)
  }

  const isAdmin = rest.applicationState.authentication.user.permission === "administrator"

  useEffect(() => {
    getTestConfiguration()
  }, [])

  const getTestConfiguration = async () => {
    setLoading(true)
    const result = await makeApiCall("get", `/api/test-configuration`)
    setTestConfiguration(result.body)
    setLoading(false)
  }

  const updateTemplate = async (formState) => {
    const result = await makeApiCall("put", `/api/document/template`, formState)
    if (result.ok) {
      alert.show("Successfully updated", {
        type: "success",
      })
      refetch()
    } else {
      const details = `Details: code ${result.statusCode}; ${result.error}`
      alert.show(details, {
        type: "error",
      })
    }

    return result
  }

  useEffect(() => {
    return () => {
      remove()
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "File Name",
        accessor: "id",
        hasSort: true,
      },
      {
        Header: "Type",
        accessor: "templateType",
        hasSort: true,
      },
      {
        Header: "Test Group",
        accessor: "testGroup",
        hasSort: true,
      },
      {
        Header: "Test Types",
        accessor: "testTypes",
        hasSort: true,
      },
      {
        Header: "Created At",
        accessor: "dateCreated",
        hasSort: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        skipCsv: true,
      },
    ],
    [],
  )

  const activeTemplate = templateListData?.find((item) => item?.id === query.get("id"))

  const downloadReport = async (_template) => {
    try {
      setDownloadId(_template?.id)
      setDownloading(true)
      const response = await makeApiDownloadCall(
        "get",
        `/api/document/template/${_template?.id}`,
        {},
        "application/pdf",
        `${_template?.id}`,
        // true,
      )
      const activeDownload = templateListData?.find((item) => item?.id === _template?.id)

      if (!response?.ok) {
        alert.show(`${_template?.id}} is not yet available`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setDownloading(false)
    }
  }

  const data = useMemo(
    () =>
      (templateListData &&
        templateListData?.map((item) => ({
          ...item,
          dateCreated: item?.dateCreated ? dayjs(item?.dateCreated).format("MM/DD/YYYY") : "",
          actions: (isAdmin || hasRole("laboratoryDirector")) && (
            <div className="d-flex gap-2 text-nowrap">
              <Link to={`/templates?${getEncodedQuery({ id: item.id, testId: "" }, "", query.toString())}`} className="btn btn-outline-primary">
                View
              </Link>
              <Button color="primary" disabled={downloadId === item?.id && downloading} onClick={() => downloadReport(item)}>
                {downloadId === item?.id && downloading ? <Spinner size="sm" /> : <i className="fa fa-download" />}
              </Button>
            </div>
          ),
        }))) ||
      [],
    [templateListData, downloadId, downloading],
  )

  if (query?.get("id")) {
    return (
      <div>
        {isLoading ? (
          <Spinner type="grow" />
        ) : (
          <GeneralFormTemplatesNew
            close={() => navigate(-1)}
            formDefinition={formStructure}
            formState={activeTemplate}
            lookups={lookups}
            update={updateTemplate}
            submit={updateTemplate}
            formSource={[
              {
                name: "testTypeData",
                data: testConfiguration,
              },
            ]}
            {...rest}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      <TableContainer
        downloadFileName={downloadFileName}
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || loading}
        filters={[]}
        selector={selector}
        defaultSort="dateCreated"
        defaultSortDir="asc"
        manualSortBy={false}
        allowRefresh
        allowDownload
        {...rest}
      />
    </div>
  )
}
